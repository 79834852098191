import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import {
    BOOKING_BUY_BANNER,
    CHECK_STOCK_BANNER,
    CHECK_THE_AVAILABLE_BANNER,
    DESIGN_BANNER,
    MAKE_THE_RESERVATION_BANNER,
    OUR_COLLECTION_BANNER,
    TRACKING,
    WE_TAKE_CARE_BANNER,
    WELCOME_WOW,
    WOW_BANNER,
} from '../../../../i18n/translations/TR';
import { Spinner } from '../../../shared/Spinner';
import { ServerErrorMessage } from '../../../shared/ServerErrorMessage';
import { SwiperBackground } from '../../../shared/SwiperBackground';
import IconRest from '../../../../assets/img/icons/ic-buy-res.svg';
import IconTrack from '../../../../assets/img/icons/ic-track.svg';
import IconStock from '../../../../assets/img/icons/ic-stock.svg';
import { FeatureItem } from './featureItem/FeatureItem';
import { ApiCollection } from '../../infrastructure/api/response/ApiCollection';

interface MainBannerProps {
    collections: ApiCollection[]
    isGettingCollections: boolean
    errorGettingCollections: boolean
}
export const MainBanner = (props: MainBannerProps): ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToCollections = ():void => {
        navigate(TencerRoutes.COLLECTIONS);
    };

    return (
        <section className="banner">
            {!props.isGettingCollections ?
                (
                    <div>
                        {!props.errorGettingCollections ?
                            (
                                <>
                                    <div className="container">
                                        <h1 className="home-title">
                                            {t(WOW_BANNER)}
                                            <span> </span>
                                            <span className="f-serif">
                                                {t(DESIGN_BANNER)}
                                            </span>
                                        </h1>

                                        <p className="intro-title">
                                            {t(WELCOME_WOW)}
                                        </p>

                                        <div className="wow-features">
                                            <FeatureItem
                                                image={IconStock}
                                                title={t(CHECK_STOCK_BANNER)}
                                                description={t(CHECK_THE_AVAILABLE_BANNER)}
                                            />

                                            <FeatureItem
                                                image={IconRest}
                                                title={t(BOOKING_BUY_BANNER)}
                                                description={t(MAKE_THE_RESERVATION_BANNER)}
                                            />

                                            <FeatureItem
                                                image={IconTrack}
                                                title={t(TRACKING)}
                                                description={t(WE_TAKE_CARE_BANNER)}
                                            />

                                        </div>
                                        <button
                                            type="button"
                                            onClick={goToCollections}
                                            className="btn btn-primary"
                                        >
                                            {t(OUR_COLLECTION_BANNER)}
                                        </button>
                                    </div>

                                    <SwiperBackground
                                        classNameProp="swiper-container-hero swiper-banner-home"
                                        images={props.collections.map((collection) => collection.imageHeader)}
                                    />
                                </>
                            ) : <ServerErrorMessage />}
                    </div>
                ) : <Spinner classCss="header-section-spinner" /> }
        </section>
    );
};
