import { ApiProduct } from 'app/home/infrastructure/api/response/ApiProduct';
import { useEffect, useState } from 'react';
import { TencerApiClientSingleton } from '../../shared/hooks/TencerApiClientSingleton';

export interface Response {
    products: ApiProduct[],
    fetchProducts: (page: number) => Promise<void>;
    isLoading: boolean,
    fetchData: (page: number) => Promise<void>,
    hasMore: boolean,
    fetchMore: () => Promise<void>,
}

export const useProductOutletController = (): Response => {
    const resultsPerPage = 20;
    const APIClient = TencerApiClientSingleton.getInstance();
    const [products, setProducts] = useState<ApiProduct[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState(true);
    const [actualPage, setActualPage] = useState(1);

    const fetchProducts = async (page: number): Promise<void> => {
        const result = await APIClient.getTrendingProducts(
            resultsPerPage,
            page,
        );

        setHasMore(result.length >= resultsPerPage);
        setActualPage(page);
        const productsMapped = result.map<ApiProduct>((product: ApiProduct) => product);

        if (page === 1) {
            setProducts(productsMapped);
        } else {
            setProducts([...products, ...productsMapped]);
        }

        setIsLoading(false);
    };
    const fetchData = async (page: number): Promise<void> => {
        if (!hasMore) {
            return;
        }
        await fetchProducts(page);
    };

    const fetchMore = async (): Promise<void> => {
        const nextPage = actualPage + 1;
        return fetchData(nextPage);
    };

    const init = async (): Promise<void> => {
        setIsLoading(true);
        await fetchData(actualPage);
    };
    useEffect(() => {
        init();
    }, []);

    return {
        products,
        fetchProducts,
        isLoading,
        fetchData,
        hasMore,
        fetchMore,
    };
};
