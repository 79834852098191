import { useEffect, useState } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { ErpUserId } from 'app/account/domain/value-objects/erp-user-id';
import { PatchErpUserPasswordDTO } from 'app/account/infrastructure/api/dto/request/patch-erp-user-password.DTO';
import { ShippingAddress } from '../../orders/model/ShippingAddress';
import { useShippingAddressRepository } from '../../orders/application/hooks/useShippingAddressRepository';
import { ShippingAddressMapper } from '../../orders/infrastructure/repository/api/mappers/ShippingAddressMapper';
import { CountryByProvince } from '../../orders/model/countryByProvince';
import { ShippingAddressFormData } from '../../orders/application/components/ShippingAddressForm/ShippingAddressForm';
import { ErpUser } from '../../account/domain/erp-user';
import { GetErpUserRequestDTO } from '../../account/infrastructure/api/dto/request/get-erp-user-request.DTO';
import { TencerApiClientSingleton } from './TencerApiClientSingleton';
import { ErpUserMapper } from '../../account/infrastructure/api/data-mapper/erp-user.mapper';
import { useToken } from './use-token';
import { ErpContactsArray } from '../../account/domain/erp-contacts-array';
import { ErpContactArrayMapper } from '../../account/infrastructure/api/data-mapper/erp-contact-array.mapper';
import { AddErpUserContactRequestDTO } from '../../account/infrastructure/api/dto/request/add-erp-user-contact-request.DTO';
import { UserCredentialId } from '../domain/value-objects/UserCredentialId';

export interface UseAccountResponse {
    createShippingAddressHandler: (request: ShippingAddressFormData) => Promise<void>;
    erpUser: ErpUser | undefined;
    getErpUserIsLoading: boolean;
    erpUserContacts: ErpContactsArray;
    getErpUserContacts: () => void;
    getErpUserContactsIsLoading: boolean;
    showDeleteContactModal: boolean;
    handleShowDeleteContactModal: () => void;
    handleHideDeleteContactModal: () => void;
    deleteErpUserContact: (email: string) => void;
    deleteErpUserContactIsLoading: boolean;
    mutationAddErpUserContact: UseMutationResult<string, unknown, AddErpUserContactRequestDTO, unknown>;
    shippingAddresses: ShippingAddress[];
    getShippingAddressesIsLoading: boolean;
    defaultShippingAddress: ShippingAddress | undefined;
    getDefaultShippingAddress: () => void;
    getDefaultShippingAddressIsLoading: boolean;
    countries: CountryByProvince[];
    mutationChangePassword: UseMutationResult<string, unknown, PatchErpUserPasswordDTO, unknown>
    getShippingAddresses: () => void;
    userCredentialId: UserCredentialId | undefined;
}

export const useAccount = (): UseAccountResponse => {
    const UseToken = useToken();
    const [erpUser, setErpUser] = useState<ErpUser>();
    const apiClient = TencerApiClientSingleton.getInstance();
    const [erpUserContacts, setErpUserContacts] = useState<ErpContactsArray>(new ErpContactsArray());
    const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
    const shippingAddressRepository = useShippingAddressRepository();

    const [countries, setCountries] = useState<CountryByProvince[]>([]);

    const handleShowDeleteContactModal = (): void => {
        setShowDeleteContactModal(true);
    };

    const handleHideDeleteContactModal = (): void => {
        setShowDeleteContactModal(false);
    };
    const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>([]);
    const [defaultShippingAddress, setDefaultShippingAddress] = useState<ShippingAddress>();
    const userCredentialId = UseToken.username ? new UserCredentialId(UseToken.username) : undefined;

    const mutationGetUser = useMutation(
        async (getUserRequestDTO: GetErpUserRequestDTO) => apiClient.getErpUser(getUserRequestDTO),
        {
            onSuccess: (data) => {
                setErpUser(ErpUserMapper.toDomain(data));
            },
        },
    );
    const getErpUser = (): void => {
        if (UseToken.erpCode) {
            mutationGetUser.mutate({
                erpUserId: UseToken.erpCode,
            });
        }
    };

    const mutationGetErpUserContacts = useMutation(async () => apiClient.getErpUserContacts(), {
        onSuccess: (data) => {
            setErpUserContacts(ErpContactArrayMapper.toDomain(data.user_contacts));
        },
    });

    const getErpUserContacts = (): void => {
        mutationGetErpUserContacts.mutate();
    };

    const mutationDeleteErpUserContact = useMutation(
        async (email: string) => apiClient.fetchDeleteErpUserContact(email),
        {
            onSuccess: () => {
                getErpUserContacts();
                handleHideDeleteContactModal();
            },
        },
    );

    const deleteErpUserContact = (email: string): void => {
        mutationDeleteErpUserContact.mutate(email);
    };

    const mutationAddErpUserContact = useMutation(
        async (
            addErpUserContactRequestDto: AddErpUserContactRequestDTO,
        ) => apiClient.fetchAddErpUserContact(addErpUserContactRequestDto),
        {
            onSuccess: () => {
                getErpUserContacts();
            },
        },
    );

    const getShippingAddresses = (): void => {
        if (UseToken.erpCode) {
            shippingAddressRepository.getShippingAddressesMutation.mutate(
                {
                    erpCode: new ErpUserId(UseToken.erpCode),
                    limit: 1000,
                    excludeDefault: false,
                },
                {
                    onSuccess: (data) => {
                        setShippingAddresses(data);
                    },
                },
            );
        }
    };

    const mutationGetDefaultShippingAddress = useMutation(
        async () => (
            UseToken.username ? apiClient.fetchGetDefaultShippingAddress({ userCredentialId: UseToken.username }) : null
        ),
        {
            onSuccess: (data) => {
                if (data) {
                    setDefaultShippingAddress(ShippingAddressMapper.fromDefaultResponse(data));
                }
            },
        },
    );

    const getDefaultShippingAddress = (): void => {
        mutationGetDefaultShippingAddress.mutate();
    };

    const getUserCountries = (): void => {
        const request: void = undefined;
        shippingAddressRepository.getUserCountriesMutation.mutate(request, {
            onSuccess: (data) => {
                setCountries(data);
            },
        });
    };

    const mutationChangePassword = useMutation(async (
        patchErpUserPasswordDto: PatchErpUserPasswordDTO,
    ) => apiClient.patchErpUserPassword(patchErpUserPasswordDto));

    const getErpUserIsLoading = mutationGetUser.isLoading;
    const getErpUserContactsIsLoading = mutationGetErpUserContacts.isLoading;
    const deleteErpUserContactIsLoading = mutationDeleteErpUserContact.isLoading;
    const getShippingAddressesIsLoading = shippingAddressRepository.getShippingAddressesMutation.isLoading;
    const getDefaultShippingAddressIsLoading = mutationGetDefaultShippingAddress.isLoading;

    const createShippingAddressHandler = (request: ShippingAddressFormData): Promise<void> => {
        return shippingAddressRepository.postShippingAddressMutation.mutateAsync(
            {
                description: request.description || null,
                country_id: request.countryId || null,
                province_id: request.provinceId || null,
                postal_code: request.postalCode || null,
                locality: request.locality || null,
                address: request.address || null,
                phone: request.phone || null,
                address1: request.address1 || null,
                address2: request.address2 || null,
                fax: request.fax || null,
            },
            {
                onSuccess: () => {
                    getShippingAddresses();
                },
            },
        );
    };
    useEffect((): void => {
        getErpUser();
        getErpUserContacts();
        getUserCountries();
        getDefaultShippingAddress();
    }, []);

    useEffect((): void => {
        getShippingAddresses();
    }, [defaultShippingAddress]);

    return {
        erpUser,
        getErpUserIsLoading,
        erpUserContacts,
        getErpUserContacts,
        getErpUserContactsIsLoading,
        showDeleteContactModal,
        handleShowDeleteContactModal,
        handleHideDeleteContactModal,
        deleteErpUserContact,
        deleteErpUserContactIsLoading,
        mutationAddErpUserContact,
        shippingAddresses,
        getShippingAddressesIsLoading,
        defaultShippingAddress,
        getDefaultShippingAddress,
        getDefaultShippingAddressIsLoading,
        mutationChangePassword,
        userCredentialId,
        countries,
        createShippingAddressHandler,
        getShippingAddresses,
    };
};
