import { CompanyId } from 'app/shared/domain/value-objects/CompanyId';
import { Id } from 'app/shared/domain/value-objects/id';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { File } from './File';
import OrderLine from './OrderLine';
import { ShippingAddressId } from './ShippingAddressId';
import { ShippingAgentCode } from './value-objects/ShippingAgentCode';
import { Price } from './value-objects/Price';
import { PackageTrackingNo } from './value-objects/PackageTrackingNo';
import { Weight } from './value-objects/Weight';
import { Year } from './value-objects/Year';
import { Status } from './value-objects/Status';
import { ShippingAddress } from './ShippingAddress';
import Transport from './transport';
import { Discount } from './value-objects/Discount';
import { ICreditMemo } from './CreditMemo';

export default class Order {

    private readonly _id: OrderId;
    public get id(): OrderId {
        return this._id;
    }

    private readonly _company: CompanyId;
    public get company(): CompanyId {
        return this._company;
    }

    private readonly _year: Year;
    public get year(): Year {
        return this._year;
    }

    private readonly _shipping: ShippingAddressId | null;
    public get shipping(): ShippingAddressId | null {
        return this._shipping;
    }

    private readonly _lines: OrderLine[];
    public get lines(): OrderLine[] {
        return this._lines;
    }

    private readonly _orderDate: Date | null;
    public get order_date(): Date | null {
        return this._orderDate;
    }

    private readonly _userClientRef: UserClientRef | null;
    public get user_client_ref(): UserClientRef | null {
        return this._userClientRef;
    }

    private readonly _files: File[] | undefined;
    public get files():File[] | undefined {
        return this._files;
    }

    private readonly _weightGross: Weight | null;
    public get weightGross(): Weight | null {
        return this._weightGross;
    }
    private readonly _weightNet: Weight | null;
    public get weightNet(): Weight | null {
        return this._weightNet;
    }

    private readonly _gross: Price;
    public get gross(): Price {
        return this._gross;
    }
    private readonly _net: Price | null;
    public get net(): Price | null {
        return this._net;
    }

    private readonly _shippingAgentCode: ShippingAgentCode | null;
    public get shippingAgentCode(): ShippingAgentCode | null {
        return this._shippingAgentCode;
    }
    private readonly _packageTrackingNo: PackageTrackingNo | null;
    public get packageTrackingNo(): PackageTrackingNo | null {
        return this._packageTrackingNo;
    }

    private readonly _status: Status | null;
    public get status(): Status | null {
        return this._status;
    }

    private readonly _shippingAddress: ShippingAddress | null;
    public get shippingAddress(): ShippingAddress | null {
        return this._shippingAddress;
    }

    // TODO: create OrderTransport??
    private readonly _transport: Transport | null;
    public get transport(): Transport | null {
        return this._transport;
    }

    private readonly _discounts: Discount[];
    public get discounts(): Discount[] {
        return this._discounts;
    }
    private readonly _creditMemo: ICreditMemo | undefined;

    public get creditMemo(): ICreditMemo | undefined {
        return this._creditMemo;
    }

    constructor(
        id: Id,
        company: CompanyId,
        year: Year,
        shipping: ShippingAddressId | null,
        lines: OrderLine[],
        orderDate: Date | null,
        userClientRef: UserClientRef | null,
        files: File[] | undefined,
        weightGross: Weight | null,
        weightNet: Weight | null,
        gross: Price | null,
        net: Price | null,
        shippingAgentCode: ShippingAgentCode | null,
        packageTrackingNo: PackageTrackingNo | null,
        status: Status | null,
        shippingAddress: ShippingAddress | null,
        transport: Transport | null,
        creditMemo: ICreditMemo | undefined,
        discounts: Discount[] = [],
    ) {
        this._id = id;
        this._company = company;
        this._year = year;
        this._shipping = shipping;
        this._lines = lines;
        this._orderDate = orderDate;
        this._userClientRef = userClientRef;
        this._files = files;
        this._weightGross = weightGross;
        this._weightNet = weightNet;
        this._gross = gross || new Price(0.0);
        this._net = net;
        this._shippingAgentCode = shippingAgentCode;
        this._packageTrackingNo = packageTrackingNo;
        this._status = status;
        this._shippingAddress = shippingAddress;
        this._transport = transport;
        this._discounts = discounts;
        this._creditMemo = creditMemo;
    }

}
