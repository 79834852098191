import { EnvEnum } from 'app/shared/domain/value-objects/EnvEnum';
import packageData from './package.base.json';

export class Environment {

    public static APP_VERSION = packageData.version;
    public static API_TIMEOUT = 50000;

    public static API_HOST: string = (():string => {
        if (process.env.REACT_APP_ENV === EnvEnum.STAGE) {
            return 'https://wow-stage-api.cuatroochenta.com';
        }
        if (process.env.REACT_APP_ENV === EnvEnum.PROD) {
            return 'https://api.customerswow.com';
        }

        return 'http://localhost:8080';
    })();

    public static STRIPE_PUBLISHING_KEY: string = (():string => {
        // eslint-disable-next-line max-len
        return 'pk_test_51N0WDlDniKAgGpfzhHCvssSyeKS7YFbZ8GNQzboTm4MZm376geOmWzr6goWXlXY0Zax98m3LTGpRiE4Joge747O8009vTF7kAb';
    })();

    public static GA_CODE: string = (():string => {
        if (process.env.REACT_APP_ENV === EnvEnum.STAGE) {
            return 'G-RL6DEL8WMC';
        }
        if (process.env.REACT_APP_ENV === EnvEnum.PROD) {
            return 'G-QB5R1EYKB9';
        }

        return '';
    })();

    public static API_URL: string = (():string => {
        if (process.env.REACT_APP_ENV === EnvEnum.STAGE) {
            return ' https://wow-stage-admin.cuatroochenta.com/';
        }
        if (process.env.REACT_APP_ENV === EnvEnum.PROD) {
            return 'https://admin.customerswow.com/';
        }

        return 'http://localhost:8080';
    })();

}
