import { PaymentTerms } from './PaymentTerms';

export class PaymentInfo {

    constructor(
        public readonly _paymentMethod: string,
        public readonly _terms?: PaymentTerms,
    ) {}

    public get paymentMethod(): string {
        return this._paymentMethod;
    }
    public get terms(): PaymentTerms | undefined {
        return this._terms;
    }

}
