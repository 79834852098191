import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShippingAddress } from '../../../../../../orders/model/ShippingAddress';
import { ErpUserId } from '../../../../../domain/value-objects/erp-user-id';
import { useShippingAddressRepository } from '../../../../../../orders/application/hooks/useShippingAddressRepository';
import { ShippingAddressId } from '../../../../../../orders/model/ShippingAddressId';
import { ReactComponent as IcAdd } from '../../../../../../../assets/img/icons/ic-add.svg';
import {
    DEFAULT_SHIPPING_ADDRESS_LABEL,
    ORDER_ADD_SHIPPING_ADDRESS,
    SHIPPING_ADDRESS_DELETE_CONFIRM_BODY,
    SHIPPING_ADDRESS_DELETE_CONFIRM_TITLE,
    SHIPPING_ADDRESS_HIDE_ALL_BUTTON,
    SHIPPING_ADDRESS_SHOW_ALL_BUTTON, YOU_MUST_SELECT_A_SHIPPING_ADDRESS,
} from '../../../../../../../i18n/translations/TR';
import { CountryByProvince } from '../../../../../../orders/model/countryByProvince';
import { UserCredentialId } from '../../../../../../shared/domain/value-objects/UserCredentialId';
import {
    ShippingAddressModal,
    ShippingAddressModalMode,
} from './account-shipping-address-card-item/shipping-address-modal';
import { ConfirmationModal } from '../../../../../../shared/components/modal/ConfirmationModal';
import { Spinner } from '../../../../../../shared/Spinner';
import { useToken } from '../../../../../../shared/hooks/use-token';
import { Origin } from '../../../../../../shared/domain/value-objects/Origin';
import { ShippingAddressSelector } from './shipping-address-selector/shipping-address-selector';
import { EditButton } from '../../../../../../shared/components/button/EditButton';
import { DeleteButton } from '../../../../../../shared/components/button/DeleteButton';

interface AccountShippingAddressCardProps {
    shippingAddresses: ShippingAddress[]
    erpUserId: ErpUserId
    userCredentialId: UserCredentialId
    onSelect?: (shippingAddress: ShippingAddress) => void | undefined
    handleHideShippingAddressSelector?: () => void
    countries: CountryByProvince[]
    defaultShippingAddress: ShippingAddress | undefined
    getShippingAddressesIsLoading: boolean
    getShippingAddresses: () => void
    getDefaultShippingAddress: () => void
    getDefaultShippingAddressIsLoading?: boolean
    handleIsChangingDefaultShippingAddress?: (state: boolean)=> void
    defaultAddress: ShippingAddress | undefined
}

// TODO (Álvaro) refactor and move to shared folder
export const AccountShippingAddressCard = (props: AccountShippingAddressCardProps): React.ReactElement => {
    const shippingAddressRepository = useShippingAddressRepository();
    const [isBusy, setIsBusy] = React.useState<ShippingAddressId>();
    const [showShippingAddressModal, setShowShippingAddressModal] = React.useState(false);
    const [showAddresses, setShowAddresses] = React.useState<boolean>(false);
    const [showEditForm, setShowEditForm] = React.useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
    const [actualAddress, setActualAddress] = useState<ShippingAddress>();
    const UseToken = useToken();
    const { t } = useTranslation();

    const handleShowEditModal = (addressToEdit: ShippingAddress): void => {
        setActualAddress(addressToEdit);
        setShowEditForm(true);
    };

    const handleCloseEditModal = (): void => {
        setActualAddress(undefined);
        setShowEditForm(false);
    };

    const handleShowDeleteConfirmationModal = (addressToDelete: ShippingAddress): void => {
        setActualAddress(addressToDelete);
        setShowDeleteConfirmation(true);
    };

    const handleCloseDeleteConfirmationModal = (): void => {
        setActualAddress(undefined);
        setShowDeleteConfirmation(false);
    };

    const onSelect = (shippingAddress: ShippingAddress): void => {
        setIsBusy(shippingAddress.code);
        if (props.handleIsChangingDefaultShippingAddress) {
            props.handleIsChangingDefaultShippingAddress(true);
        }
        shippingAddressRepository.putDefaultShippingAddressMutation.mutate(
            {
                erpCode: props.erpUserId.value,
                shippingAddressId: shippingAddress.code,
                userCredentialId: props.userCredentialId,
            },
            {
                onSuccess: () => {
                    setIsBusy(undefined);
                    if (props.handleHideShippingAddressSelector) {
                        props.handleHideShippingAddressSelector();
                    }
                    if (props.onSelect) {
                        props.onSelect(shippingAddress);
                    }
                    if (props.handleIsChangingDefaultShippingAddress) {
                        props.handleIsChangingDefaultShippingAddress(false);
                    }
                },
            },
        );
    };

    const onAddSuccess = (): void => {
        setShowShippingAddressModal(false);
        props.getShippingAddresses();
    };

    const onRemove = (): void => {
        props.getShippingAddresses();
        props.getDefaultShippingAddress();
    };

    const onChange = (): void => {
        props.getShippingAddresses();
        props.getDefaultShippingAddress();
    };

    const deleteShippingAddressHandler = (shippingAddressId: ShippingAddressId): void => {
        setShowDeleteConfirmation(true);
        shippingAddressRepository.deleteShippingAddressMutation.mutate(
            {
                shippingAddressId,
            },
            {
                onSuccess: () => {
                    setShowDeleteConfirmation(false);
                    onRemove();
                },
            },
        );
    };

    const isLoading = props.getDefaultShippingAddressIsLoading || props.getShippingAddressesIsLoading;

    return (
        <>
            <div className="row account-card">
                <div className="row">
                    <div className="col-sm-8">
                        <h5 className="card-wow-title">{t('ORDER_SHIPPING_ADDRESSES')}</h5>
                        <p className="account-card-subtitle">{t('ORDER_SHIPPING_ADDRESSES_SUBTITLE')}</p>
                    </div>
                    <div className="col-sm-4 add-shipping-address-button-container">
                        {UseToken.origin === Origin.USA ? (
                            <button
                                type="button"
                                className="btn action-add add-shipping-address"
                                onClick={(): void => setShowShippingAddressModal(true)}
                                aria-hidden
                            >
                                <IcAdd />
                                <span className="add-contact-item-text">{t(ORDER_ADD_SHIPPING_ADDRESS)}</span>
                            </button>
                        ) : null}
                    </div>
                </div>

                <div className="account-card-default-address-container">
                    <label className="form-label" htmlFor="defaultAddress">
                        {t(DEFAULT_SHIPPING_ADDRESS_LABEL)}
                    </label>

                    {!isLoading && !isBusy ? (
                        <div className="default-address-info-actions-container">
                            {props.defaultShippingAddress ? (
                                <span className="default-address-info">
                                    {props.defaultShippingAddress.buildAddressInfo()}
                                </span>
                            ) : (
                                <span className="default-address-info">
                                    {t(YOU_MUST_SELECT_A_SHIPPING_ADDRESS)}
                                </span>
                            )}

                            {props.defaultShippingAddress?.portal && UseToken.origin === Origin.USA ? (
                                <div className="default-address-actions">
                                    <EditButton onClick={(e): void => {
                                        e.stopPropagation();
                                        handleShowEditModal(props.defaultShippingAddress!);
                                    }}
                                    />
                                    <DeleteButton
                                        onClick={(e): void => {
                                            e.stopPropagation();
                                            handleShowDeleteConfirmationModal(props.defaultShippingAddress!);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>

                    ) : (
                        <Spinner classCss="account-card-loading-container" />
                    )}
                </div>

                <div className="account-card-add-address-button-container">
                    {showAddresses ? (
                        <button
                            type="button"
                            className="btn btn-link account-card-add-address-button"
                            onClick={(): void => setShowAddresses(false)}
                            aria-hidden
                        >
                            {t(SHIPPING_ADDRESS_HIDE_ALL_BUTTON)}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-link account-card-add-address"
                            onClick={(): void => setShowAddresses(true)}
                            aria-hidden
                        >
                            {t(SHIPPING_ADDRESS_SHOW_ALL_BUTTON)}
                        </button>
                    )}
                </div>

                {showAddresses ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <ShippingAddressSelector
                                onSelect={onSelect}
                                defaultAddress={props.defaultAddress}
                                handleShowEditModal={handleShowEditModal}
                                shippingAddresses={props.shippingAddresses}
                                handleShowDeleteConfirmationModal={handleShowDeleteConfirmationModal}
                            />
                        </div>
                    </div>
                ) : null}

            </div>

            <ShippingAddressModal
                show={showShippingAddressModal}
                countries={props.countries}
                onClose={(): void => setShowShippingAddressModal(false)}
                onSuccess={onAddSuccess}
                mode={ShippingAddressModalMode.ADD}
            />

            {actualAddress ? (
                <ShippingAddressModal
                    show={showEditForm}
                    onClose={handleCloseEditModal}
                    countries={props.countries}
                    address={actualAddress}
                    onSuccess={(): void => {
                        setShowEditForm(false);
                        onChange();
                    }}
                    mode={ShippingAddressModalMode.EDIT}
                />
            ) : null}

            {actualAddress ? (
                <ConfirmationModal
                    show={showDeleteConfirmation}
                    isBusy={shippingAddressRepository.deleteShippingAddressMutation.isLoading}
                    onClose={handleCloseDeleteConfirmationModal}
                    onAccept={(): void => deleteShippingAddressHandler(actualAddress.code)}
                    title={t(SHIPPING_ADDRESS_DELETE_CONFIRM_TITLE)}
                    body={t(SHIPPING_ADDRESS_DELETE_CONFIRM_BODY)}
                />
            ) : null}
        </>
    );
};

AccountShippingAddressCard.defaultProps = {
    onSelect: undefined,
    handleHideShippingAddressSelector: ():void => { /* Nothing to do */ },
    getDefaultShippingAddressIsLoading: false,
};
