import { PaymentTerms } from '../../../../orders/model/value-objects/PaymentTerms';
import { PaymentTermsResponse } from '../dto/responses/PaymentTermsResponse';

export const PaymentTermsMapper = {

    map(data: PaymentTermsResponse): PaymentTerms {
        return {
            amount: data.credit_available,
            currency: '$',
            period: `${this.stripDSuffix(data.limit_days)}`,
        };
    },

    stripDSuffix(value: string): number {
        return Number(value.endsWith('D') ? value.slice(0, -1) : value);
    },

};
