import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CourierServiceField } from './fields/CourierServiceField';
import { CommentsField } from './fields/CommentsField';
import { ConsolidatedField } from './fields/ConsolidatedField';
import { TransportFormName, TransportFormRegisters } from '../../../../../domain/entity/TransportForm';
import { ResponsibleField } from './fields/ResponsibleField';
import { SHIPPING_METHOD } from '../../../../../../../i18n/translations/TR';
import { CourierField } from './fields/CourierField';
import { ETransportType } from '../../../../../../orders/model/value-objects/transport-type';
import Cart from '../../../../../../cart/domain/model/Cart';
import { UseCartSummaryResponse } from '../../../../hooks/useCartSummary';
import { LiftGateField } from './fields/LiftGateField';

interface OrderTransportFormProps {
    cart: Cart | undefined,
    UseCartSummary: UseCartSummaryResponse
    UseTransportForm: UseFormReturn<TransportFormRegisters>
}

export const OrderTransportForm = (props: OrderTransportFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const resetFields = (): void => {
        props.UseTransportForm.resetField(TransportFormName.COMMENTS);
        props.UseTransportForm.resetField(TransportFormName.LIFT_GATE);
        props.UseTransportForm.resetField(TransportFormName.COURIER);
        props.UseTransportForm.resetField(TransportFormName.COURIER_SERVICE);
    };

    useEffect(() => {
        const transport = props.cart?.transport;
        props.UseTransportForm.resetField(TransportFormName.COMMENTS, {
            defaultValue: transport?.comments,
        });
        props.UseTransportForm.resetField(TransportFormName.LIFT_GATE, {
            defaultValue: transport?.liftGate,
        });
        props.UseTransportForm.resetField(TransportFormName.CONSOLIDATED, {
            defaultValue: transport?.isConsolidated,
        });
        props.UseTransportForm.resetField(TransportFormName.COURIER, {
            defaultValue: transport?.courier?.id.value,
        });
        props.UseTransportForm.resetField(TransportFormName.COURIER_SERVICE, {
            defaultValue: transport?.courierService?.id.value,
        });
        props.UseTransportForm.resetField(TransportFormName.RESPONSIBLE, {
            defaultValue: transport?.type.value,
        });
    }, []);

    const watchLiftGate = props.UseTransportForm.watch(TransportFormName.LIFT_GATE);
    const watchResponsible = props.UseTransportForm.watch(TransportFormName.RESPONSIBLE);
    const watchAllowQuoteTransport = props.UseTransportForm.watch(TransportFormName.ALLOW_QUOTE_TRANSPORT);

    const responsibleRegister = props.UseTransportForm.register(TransportFormName.RESPONSIBLE, {
        required: true,
        onChange: (): void => {
            resetFields();
        },
    });

    const courierRegister = props.UseTransportForm.register(TransportFormName.COURIER, {
        required: new Set([
            ETransportType.OWN_LOGISTIC,
            ETransportType.SMALL_PARCEL,
            ETransportType.WOW,
        ]).has(watchResponsible),
    });
    const courierServiceRegister = props.UseTransportForm.register(TransportFormName.COURIER_SERVICE, {
        required: new Set([ETransportType.OWN_LOGISTIC, ETransportType.SMALL_PARCEL]).has(watchResponsible),
    });

    const courierError = props.UseTransportForm.formState.errors.courier;
    const courierServiceError = props.UseTransportForm.formState.errors.courierService;
    const commentsRegister = props.UseTransportForm.register(TransportFormName.COMMENTS);
    const liftGateRegister = props.UseTransportForm.register(TransportFormName.LIFT_GATE);
    const consolidatedRegister = props.UseTransportForm.register(TransportFormName.CONSOLIDATED);

    return (

        <form className="transport-form-container">

            <ConsolidatedField
                consolidatedRegister={consolidatedRegister}
            />

            <div className="cart-content-subtitle">
                <span>{t(SHIPPING_METHOD)}</span>
            </div>

            <ResponsibleField
                responsibleRegister={responsibleRegister}
                allowQuoteTransport={watchAllowQuoteTransport}
                disabled={props.UseCartSummary.UseTransport.isGettingCouriers ||
                props.UseCartSummary.UseTransport.isGettingWowCouriers ||
            props.UseCartSummary.UseTransport.isGettingCouriersServices}
            />

            {watchResponsible !== ETransportType.WILL_CALL ? (
                <>
                    <CommentsField
                        commentsRegister={commentsRegister}
                        watchResponsible={watchResponsible}
                    />

                    {
                        watchResponsible === ETransportType.WOW ? (
                            <>
                                <LiftGateField
                                    liftGateRegister={liftGateRegister}
                                />
                                {watchLiftGate ? (
                                    <CourierField
                                        courierFieldError={courierError}
                                        courierFieldRegister={courierRegister}
                                        couriers={props.UseCartSummary.UseTransport.liftGateCouriers}
                                        isLoading={props.UseCartSummary.UseTransport.isGettingWowCouriers}
                                    />
                                ) : null}
                                {!watchLiftGate ? (
                                    <CourierField
                                        courierFieldError={courierError}
                                        courierFieldRegister={courierRegister}
                                        couriers={props.UseCartSummary.UseTransport.noLiftGateCouriers}
                                        isLoading={props.UseCartSummary.UseTransport.isGettingWowCouriers}
                                    />

                                ) : null}
                            </>
                        ) : null
                    }

                    {
                        new Set([ETransportType.OWN_LOGISTIC, ETransportType.SMALL_PARCEL]).has(watchResponsible) ? (
                            <>
                                <CourierField
                                    courierFieldError={courierError}
                                    courierFieldRegister={courierRegister}
                                    couriers={props.UseCartSummary.UseTransport.couriers}
                                    isLoading={props.UseCartSummary.UseTransport.isGettingCouriers}
                                />
                                <CourierServiceField
                                    courierServiceFieldError={courierServiceError}
                                    courierServiceFieldRegister={courierServiceRegister}
                                    couriersServices={props.UseCartSummary.UseTransport.couriersServices}
                                    isLoading={props.UseCartSummary.UseTransport.isGettingCouriersServices}
                                />
                            </>
                        ) : null
                    }
                </>
            ) : null}
        </form>
    );
};
